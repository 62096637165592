<template>
    <div style="height: 80%;" >
       <!--table部分-->
            <el-table :data="messageList" highlight-current-row v-loading="loading" style="width: 100%;" >
                <el-table-column label="消息内容" align="center">
                    <template slot-scope="props">
                        <span style="cursor: pointer; color: #409EFF;" @click="viewMessage(props.row)">{{ props.row.message.length > 15 ? `${props.row.message.slice(0, 15)}...` : props.row.message }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="isRead" label="状态" align="center">
                    <template slot-scope="props">
                        <el-tag
                                :type="props.row.isRead == false ? 'danger' : props.row.isRead == true ? 'success' : ''"
                                effect="dark"
                        >
                            {{ props.row.isRead == false ? '未读' : props.row.isRead == true ? '已读' : '' }}
                        </el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!--工具条-->
            <el-col :span="24"  v-if="messageList.length > 0">
                <el-pagination style="margin:auto"  background layout="total, prev, pager, next" @current-change="handleCurrentChange" :page-size="5" :current-page="currentPage" :total="total">
                </el-pagination>
            </el-col>
<!-- ------------------- -->


        <el-dialog
                width="30%"
                title="消息详情"
                :visible.sync="innerVisible"
                append-to-body>
            <div >
                <p>
                    {{ details.con }}
                </p>
                <p>
                    {{ details.date }}
                </p>
            </div>
            <div slot="footer" >
                <el-button type="default" size="small" @click="innerVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  GetMyMessage,
  GetMyMessageNotReadCount,
  ReadMyMessage,
} from "@/api/tip";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      innerVisible: false,
      currentPage: 1,
      page: 1,
      details: {
        con: "",
        date: "",
      },
      messageList: [],
    };
  },
  computed: {},
  methods: {
    /*切换分页*/
    handleCurrentChange(val) {
          this.currentPage = val;
          this.loadList()
      //this.page = val
      //this.$store.dispatch('GetMessage', {page: val, sysType: this.sysType})
    },
    /*查看具体信息*/
    async viewMessage(row) {
      let params = {
        id: row.id,
      };
      this.details.con = row.message;
      //this.details.date = row.createTime;
      await ReadMyMessage(params);
      this.innerVisible = true
      this.loadList()
    },
    /*关闭弹框*/
    closeModel() {

    },
    /*操作*/
    resetForm() {
      this.dialog = false;
    },
    //加载列表数据
    async loadList() {
      this.loading = true;
      let params = {
        pageSize: 5,
          pageIndex: this.currentPage,
      };
      let res = await GetMyMessage(params);
      this.loading = false;
      if (res.code == 10000) {
        this.messageList = res.data;
        this.total = res.total;
        console.log("进来了")
      }
    },
  },
  mounted: function () {
this.loadList()


  },
};
</script>

