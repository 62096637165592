import request from '../utils/request'
import baseURL from '../utils/config'

// 得到我的消息列表
export function GetMyMessage(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Tip/GetMyMessage',
        method: 'post',
        data
    })
}
//  得到未读消息数量
export function GetMyMessageNotReadCount(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Tip/GetMyMessageNotReadCount',
        method: 'get',
        params
    })
}
// 读取某条信息
export function ReadMyMessage(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Tip/ReadMyMessage',
        method: 'get',
        params
    })
}

